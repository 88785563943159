import { HTMLMotionProps, motion } from 'framer-motion';
import React, { FC } from 'react';
import { fadeIn, fadeInHeight } from '@utils/animations';

type Props = HTMLMotionProps<'div'> & {
  show: boolean;
  inline?: boolean;
  animateHeight?: boolean;
  forceMount?: boolean;
};

export const FadeInElement: FC<Props> = ({
  show,
  inline = false,
  animateHeight = true,
  forceMount = false,
  children,
  style,
  ...rest
}) => {
  const Component = inline ? motion.span : motion.div;

  if (!show && !forceMount) {
    return null;
  }

  return (
    <Component
      animate={show ? 'displayed' : 'hidden'}
      exit="hidden"
      initial="hidden"
      style={{ ...style, overflow: 'hidden' }}
      variants={animateHeight ? fadeInHeight : fadeIn}
      {...rest}
    >
      {children}
    </Component>
  );
};
